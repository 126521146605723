import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div class="row container">
        <div>
          <h1>Privacy Policy</h1>
          <p>We do not collect, use or have access to any personal data created in the Woodstock Fair App.</p>
        </div>
      </div>
    </div>
  );
}

export default App;
